import {isEventSupported} from "../../events";
import { DValidator } from "../../util/DValidator";

declare const $;

$(document).on('ready', function () {
    var validationRules;
    var errorFriendlyDescriptions;
    var validator = new DValidator();
    var $allInputs = $("input, select");
    var callbackFunctions: any = {};

    var $memberDetailsForm = $("form[name=member_details]");

    var $ylnpCheckboxDiv = $("#ylnp_branch_div");
    var $lnpwCheckboxDiv = $("#lnpw_branch_div");
    var $lnpwbranchlabel = $("#lnpw_branch-label");
    var $lnpwBranchElem = $("#lnpw_branch-element");

    var $branchEle = $("#branch");
    var $ylnpBranchEle = $("#ylnp_branch");
    var $lnpwBranchEle = $("#lnpw_branch");

    var $postcodeEle = $("#home_address-postcode");
    var $suburbEle = $("#home_address-suburb");
    var $streetAddress1Ele = $("#home_address-street_address_1");

    var phoneAreaCode = $("#phone_area_code").val();

    /**
     * Checkboxes that will skip payment screen if checked.
     *
     * @type {jQuery|HTMLElement}
     */
    var $skipPaymentCheckboxes = $(".skip-payment");

    var $modalMessage = $(".modal-alert-message");
    var $modalBlock = $(".modal-block");
    var $modalAlert = $(".modal-alert");

    var $paymentMethod = $("#payment_method");
    var $ccNumber = $("#cc_number");

    var phoneSelector = "input[type=tel]:not(#cc_number)";

    const $phoneElements = $(phoneSelector);

    const $recurringPayment = $("#recurring_payment");
    const $directDebitNote = $("#direct_debit_info_paragraph");

    var postcodeCustomErrMsg = $postcodeEle.data('required-error');
    var invalidCCNumberMessage = "Please provide a valid credit card number.";
    var amexCCNotAllowedMessage = "Sorry, we do not accept American Express.";

    initStylingWrappers();
    createJointMemberDiv();
    initHiddenElements();
    initBirthDateValidation();
    initCustomValidators();

    // Load any JSON data we need from our new_signup_data.json file.
    $.ajax({
        async: false,
        url: '/scripts/omms/shared/public/new_signup_data.json',
        dataType: 'json',
        cache: false,
        success: function (result) {
            validationRules = result[ 'validationRules' ];
            errorFriendlyDescriptions = result[ 'errorFriendlyDescriptions' ];
        }
    });

    /**
     * A function that wraps some elements in divs for the purpose of styling.
     * This is in JavaScript rather than HTML to keep our .phtml files that
     * little bit cleaner.
     */
    function initStylingWrappers() {
        // Wrap our prev and next buttons in a div to align them right.
        $(".but_next").each(function (this: any) {
            $(this).prev(".but_prev").addBack().wrapAll("<div class='but_wrapper'></div>");
        });

        // Wrap our iniline form elements (i.e. date fields) in divs so that we can
        // put some padding between them.
        $("[class*=-inline-form-elements]").children("input, select").wrap("<div></div>");
    }

    // #7827 if name badge is selected/unselected the total amount will be
    // different
    $("#name_badge").change(function () {
        calculateTotalAmount();
    });

    // Membership statuses that have the name badge price included
    var nameBadgeFeeMembershipOptions = [ "M1", "P1", "L1", "N2", "Q2", "L2" ];

    function isNameBadgeIncluded(membershipOption) {
        return !!membershipOption && nameBadgeFeeMembershipOptions.indexOf(membershipOption) === -1;
    }

    function getNameBadgePrice(membershipOption) {
        const $nameBadge = $('#name_badge');

        if (!membershipOption || !$nameBadge.is(':checked') || isNameBadgeIncluded(membershipOption)) {
            return 0;
        }

        const price = parseFloat($nameBadge.data("fee"));
        if (isNaN(price) || price < 0) {
            return 0;
        }

        return price;
    }

    function updateNameBadgePriceTextbox(membershipOption) {
        var $nameBadgeAmount = $("#name_badge_amount");
        var $nameBadge = $('#name_badge');

        if (isNameBadgeIncluded(membershipOption)) {
            $nameBadge.prop('checked', true);

            return $nameBadgeAmount.val("Included in membership fee");
        }

        var price = getNameBadgePrice(membershipOption);

        $nameBadgeAmount.val("$" + price);
    }

    function getSelectedMembershipOption() {
        const $fee = $("#fee");

        const json = $fee.val();
        if (!json) {
            return null;
        }

        return JSON.parse(json);
    }

    function calculateTotalAmount() {
        var totalAmount = 0;
        var membershipOption = '';

        var membershipOptionObject = getSelectedMembershipOption();
        if (membershipOptionObject) {
            totalAmount += parseFloat(membershipOptionObject.fee);
            membershipOption = membershipOptionObject.membership_option;
        }

        var donationAmount = $("#donation_amount").val();

        if (!isNaN(donationAmount)) {
            if (donationAmount > 0) {
                totalAmount += parseFloat(donationAmount);
            }
        }

        updateNameBadgePriceTextbox(membershipOption);

        var nameBadgePrice = getNameBadgePrice(membershipOption);

        totalAmount += nameBadgePrice;

        // #10710 - if the membership type is selected as Membership for Life we
        // need to uncheck and disable 'Automatically renew' checkbox.
        let disable_automatically_renew_indi = [ 'L1' ];
        let disable_automatically_renew_joint = [ 'L2' ];

        if (disable_automatically_renew_indi.indexOf(membershipOption) > -1 || disable_automatically_renew_joint.indexOf(membershipOption) > -1) {
            $recurringPayment.prop("checked", false);
            $recurringPayment.prop("disabled", true);
        }else {
            $recurringPayment.prop("disabled", false);
        }

        $("#total_amount").val("$" + totalAmount);
    }

    /**
     * Validates a section of our signup form.
     * @param sectionId The ID of the section to validate. (e.g.
     *   "second_screen").
     * @returns {Object|boolean}
     */
    function validateSection(sectionId) {
        if (validationRules.hasOwnProperty(sectionId)) {
            return validator.validate(validationRules[ sectionId ]);
        }else {
            return true;
        }
    }

    /**
     * A helper function that gets the current section we're in and calls
     * validateSection( $curPage )
     * @returns {Object|boolean}
     */
    function validateCurrentSection() {
        var $curPage = $(".signup-form-section:visible");
        return validateSection($curPage.attr('id'));
    }

    /**
     * Validates a single element. This only works for elements on the current
     * page.
     * @param $ele The jQuery element object.
     * @returns {Object|boolean}
     */
    function validateSingleElement($ele) {
        // Changed the way the DValidator validates.
        // Now, we check the entire set of rules even when validating a single
        // element. This is slower, but allows us to use ANY selector instead of
        // just an ID selector in our rules JSON.
        var $curPage = $(".signup-form-section:visible");

        var validationResult = validator.validateSingle($ele, validationRules[ $curPage.attr('id') ]);

        if (validationResult !== true) {
            displayValidationErrorsForSingleElement($ele, validationResult);
        }
    }

    function getErrorDivForEle($ele, createIfNotExists = true) {
        // If our element is a radio button or a checkbox,
        // we need to get the last checkbox/radio with the same name as this one.
        if ($ele[ 0 ].type === "checkbox" || $ele[ 0 ].type === "radio") {
            var eleName = $ele.attr('name');
            $ele = $("[name='" + eleName + "']:last");

            // Now get either the parent label, the immediate sibling label or the
            // checkbox/radio itself, as we want to ensure that the error div is
            // AFTER any labels.
            var $labelEle = $ele.next("label") || $ele.closest("label");

            if ($labelEle.length === 0) {
                $labelEle = $ele.closest("label");
            }

            // Use the label element as a starting point for the error div
            // if it exists, or the initial element otherwise.
            $ele = $labelEle.length > 0 ? $labelEle : $ele;
        }

        var $eleErrorDiv = $ele.next(".form-input-errors");

        if (createIfNotExists && $eleErrorDiv.length === 0) {
            // No errors div for this element yet - let's create one.
            $eleErrorDiv = $("<div class='form-input-errors'></div>");
            $ele.after($eleErrorDiv);
        }

        return $eleErrorDiv;
    }

    /**
     * Takes an error result object from DValidator->validate() and appends
     * errors to the form's inputs.
     * @param {object} validationResult
     */
    function displayValidationErrors(validationResult) {
        var $curPage = $(".signup-form-section:visible");

        // Loop through all the elements with errors and append the errors to our
        // form (only on the current page).
        for (var elementId in validationResult) {
            if (validationResult.hasOwnProperty(elementId)) {
                //var $ele = $("#" + elementId);

                var $ele = $curPage.find(elementId);

                $ele.each(function (this: any) {
                    displayValidationErrorsForSingleElement($(this), validationResult[ elementId ]);
                });
            }
        }
    }

    function displayValidationErrorsForSingleElement($ele, validationResult) {
        // Remove any errors previously displayed for this element.
        removeFormErrors($ele);

        var $eleErrorDiv = getErrorDivForEle($ele);

        // Loop through all errors for the element
        for (var errorName in validationResult) {
            if (validationResult.hasOwnProperty(errorName)) {
                var ruleValue = validationResult[ errorName ];
                // Get the friendly description for this error.
                var friendlyDescription = errorFriendlyDescriptions[ errorName ];

                // If we have a token in the friendly description, replace it with the
                // rule value.
                friendlyDescription = friendlyDescription.replace("{{RULE_VALUE}}", ruleValue);

                $eleErrorDiv.append("<p>" + friendlyDescription + "</p>")
            }
        }
    }

    /**
     * Removes errors from an element or the entire form if no element is
     * passed.
     * @param $ele (optional) A jQuery element to remove errors for.
     */
    function removeFormErrors($ele = null) {
        if ($ele === null) {
            $('.form-input-errors').remove();
        }else {
            getErrorDivForEle($ele).remove();
        }
    }

    /**
     * Removes a custom error for an element and deletes the error
     * div for the element if one exists.
     *
     * @param $ele A jQuery element to remove custom errors for.
     */
    function removeCustomError($ele) {
        validator.removeCustomError($ele);
        removeFormErrors($ele);
    }

    /**
     * Goes to the next or previous page of the signup form (if validation
     * passes).
     * @param {boolean} next True if going to next page, false if going to
     *   previous.
     * @returns {boolean} True if switched to next page, false if there is no
     *   next page.
     */
    function goToPage(next) {
        // default to next page.
        next = typeof next === 'undefined' ? true : next;

        // Get the current page div.
        var $curPage = $(".signup-form-section:visible");

        // Call either the "next" or "prev" function of jQuery.
        var funcName = next === true ? "next" : "prev";

        // Get the next or previous page.
        // We use a variable function name (funcName) to call either "prev" or
        // "next".
        var $nextPage = $curPage[ funcName ](".signup-form-section");

        // Show the next screen or submit the form.
        if ($nextPage.length === 0) {
            // This means that there is no next page.
            // Let's submit the form.
            return false;
        }

        // Hide our current page and fade in the next.
        $curPage.hide();

        $nextPage.fadeIn("fast");

        // Scroll to the top of the page.
        $("html, body").animate({scrollTop: 0}, "fast");

        return true;
    }

    /**
     * Asynchronously submits the form on the current page (if validation
     * passes) and handles the response.
     */
    function ajaxSubmitSignupForm($page, cb) {
        var $formToSubmit = $page.find("form");

        var data = $formToSubmit.serialize();
        data += "&form_name=" + encodeURIComponent($formToSubmit.attr('name'));

        $.ajax({
            method: "POST",
            data: data,
            dataType: "json",
            success: function (jsonResult) {
                if (jsonResult.errors > 0) {
                    alert("There were issues processing your application");
                }else {
                    if (typeof cb === "function") {
                        cb(jsonResult);
                    }
                }
            }
        });
    }

    /**
     * Stores any previous calls to this AJAX function so that
     * we can cancel it when we make a new request.
     */
    var prevSuburbXHR = {
        abort: function () {
        }
    };
    /**
     * A cache of previously found suburbs.
     * @type {Object}
     */
    var cachedSuburbs = {};

    function ajaxGetSuburbs(data, cb) {
        prevSuburbXHR.abort();

        // Check if we already have the suburbs for this postcode cached.
        if (typeof cb === "function") {
            var cacheKey = data.postcode;
            if (cachedSuburbs.hasOwnProperty(cacheKey)) {
                cb(cachedSuburbs[ cacheKey ]);
                return;
            }
        }

        data.return = "suburb";

        prevSuburbXHR = $.ajax({
            url: "/public/ajaxrollautocomplete",
            method: "POST",
            data: data,
            dataType: "json",
            success: function (jsonResult) {
                // Cache the results.
                cachedSuburbs[ cacheKey ] = jsonResult;

                if (typeof cb === "function") {
                    cb(jsonResult);
                }
            }
        });
    }

    /**
     * Stores any previous calls to this AJAX function so that
     * we can cancel it when we make a new request.
     */
    var prevStreetAddressAjax = {
        abort: function () {
        }
    };

    function ajaxGetStreetAddresses(data, cb) {
        prevStreetAddressAjax.abort();

        data.return = "street";

        prevStreetAddressAjax = $.ajax({
            url: "/public/ajaxrollautocomplete",
            method: "POST",
            data: data,
            dataType: "json",
            success: function (jsonResult) {
                if (typeof cb === "function") {
                    cb(jsonResult);
                }
            },
            error: function (e) {
                if (e.statusText === "abort") {
                    if (typeof cb === "function") {
                        cb({});
                    }
                }
            }
        });
    }

    function ajaxGetFeeOptions(type, cb) {
        $.ajax({
            url: "/public/ajaxgetfeeoptions",
            method: "POST",
            data: {
                type: type
            },
            dataType: "json",
            success: function (jsonResult) {
                if (typeof cb === "function") {
                    cb(jsonResult);
                }
            }
        });
    }

    // #7827 - gets the name badge fee
    function ajaxGetNameBadgeFee(type, cb) {
        $.ajax({
            url: "/public/ajaxgetnamebadgefee",
            method: "POST",
            data: {
                type: type
            },
            dataType: "json",
            success: function (jsonResult) {
                if (typeof cb === 'function') {
                    cb(jsonResult);
                }
            }
        });
    }

    function processAjaxFeeOptionsResult(jsonResult) {
        // Clear our fee select box.
        var $fee = $("#fee");
        $fee.empty();

        var optionsStr = "<option value=''>Select membership type</option>";
        for (var selectVal in jsonResult) {
            if (jsonResult.hasOwnProperty(selectVal)) {
                optionsStr += "<option value='" + selectVal + "'>" + jsonResult[ selectVal ] + "</option>";
            }
        }

        $fee.append(optionsStr);
    }

    // #7827 - set the fee according to the result we get from ajaxGetNameBadgeFee
    function processAjaxNameBadgeFeeResult(jsonResult) {
        // set data-fee to this value
        $('#name_badge').attr("data-fee", jsonResult);
    }

    function createJointMemberDiv() {
        var $jointDiv = $("#joint_member_details");

        if ($jointDiv.length === 0) {
            // We need to create the div.
            // Copy the member details div.
            var $memberDetails = $("#member_details")
            $jointDiv = $memberDetails.clone();

            // Set the ID of our new div.
            $jointDiv.attr("id", "joint_member_details");

            // Ensure it is hidden when we first insert it.
            $jointDiv.hide();

            // Change all the ID's and names of our inputs of our joint form.
            $jointDiv.find("input, select, label, h1").each(function (this: any) {
                var $this = $(this);

                if ($this.prop("tagName") === "LABEL") {
                    $this.attr("for", $this.attr('for') + "_joint");
                }else if ($this.prop("tagName") === "H1") {
                    $this.text("Member 2 Details");
                }else {
                    $this.attr("id", $this.attr("id") + "_joint");
                    $this.attr("name", $this.attr("name") + "_joint");
                }
            });

            $jointDiv.insertAfter($memberDetails);
        }
    }

    function getMemberBirthdateFromFormValues(joint = false) {
        var jointStr = joint ? "_joint" : "";
        return $("#birth_date_year" + jointStr).val() + "/" + $("#birth_date_month" + jointStr).val() + "/" + $("#birth_date_day" + jointStr).val();
    }

    /**
     * Gets the age of a person based on a date.
     * @param dateString A date in Y/m/d format.
     * @returns {number}
     */
    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    function showJointMemberDiv() {
        $("#joint_member_details").slideDown();
    }

    function hideJointMemberDiv() {
        $("#joint_member_details").slideUp();
    }

    function showModalMessage(msg, dismissible) {
        $modalMessage.html(msg);
        showModalBlock(dismissible);
        $modalAlert.slideDown();
    }

    function showModalBlock(dismissble) {
        // Ensure we remove any previously set click handlers.
        $modalBlock.off('click');

        $modalBlock.show();

        if (dismissble) {
            // After a small delay, set a one-time click handler to allow closing the
            // modal block.
            setTimeout(function () {
                $modalBlock.one('click', modalBlockClickHandler);
            }, 1500);
        }
    }

    function showAjaxSpinner() {
        showModalBlock(false);
        $(".ajax-spinner").fadeIn();
    }

    function hideAjaxSpinner() {
        $(".ajax-spinner").fadeOut("fast");
        $modalBlock.hide();
    }

    function getTextAfterDeletingSelection(ele) {
        // @ts-ignore
        if (document.selection != undefined) {
            ele.focus();
            // @ts-ignore
            document.selection.clear();
            return ele.value;
        }else if (ele.selectionStart != undefined) {
            // Mozilla version
            var startPos = ele.selectionStart;
            var endPos = ele.selectionEnd;
            return ele.value.slice(0, startPos) + ele.value.slice(endPos);
        }
    }

    function initElementInitialValues() {
        // Set our joint member checkbox to 0 so that our validator can
        // know when it is checked.
        $("#joint_member").val(0);
        $("#total_amount").val("$" + 0);
        $("#name_badge_amount").val("$" + 0);

        // If there's only one available payment method, select it
        const $paymentMethodOptions = $paymentMethod.find('option');
        const numPaymentMethods = $paymentMethodOptions.length
        if (numPaymentMethods === 2) {
            // We use 2 as there's a "Select payment method" option
            const firstOption = $paymentMethodOptions[1].value;
            $paymentMethod.val(firstOption);
            $paymentMethod.trigger('change');
        }

        // Add an error to the postcode field until a valid postcode is shown.
        // (This won't actually show until the postcode field is blurred)
        validator.setCustomError($postcodeEle, postcodeCustomErrMsg);
    }

    function initBirthDateValidation() {
        const selectors = [
            '#birth_date_day',
            '#birth_date_month',
            '#birth_date_year',
        ];

        let selectorStr = '';
        for (const suffix of ['', '_joint']) {
            // This just creates a comma-separated string of the selectors, also
            // adding our _joint suffix to each selector on the 2nd iteration
            selectorStr += `${selectors.join(`${suffix},`)}${suffix},`;
        }

        selectorStr = selectorStr.slice(0, -1);

        const $day = $('#birth_date_day');
        const $dayJoint = $('#birth_date_day_joint');

        // Set an error on the birth date dropdown until at least one of the values are changed.
        validator.setCustomError($day, 'Please update your date of birth');
        validator.setCustomError($dayJoint, 'Please update your date of birth');

        $(selectorStr).on('change', () => {
            validator.removeCustomError($day);
            validator.removeCustomError($dayJoint);

            // Check to see if members are over the age of 16
            const date = getMemberBirthdateFromFormValues();
            const jointDate = getMemberBirthdateFromFormValues(true);
            const errorMessage = "You must be over the age of 16 to complete your application.";

            if (getAge(date) < 16) {
                validator.setCustomError($day, errorMessage);
            }

            if (getAge(jointDate) < 16) {
                validator.setCustomError($dayJoint, errorMessage);
            }
        });
    }

    // Some validators can't be put into the JSON blob, initialise them here
    function initCustomValidators() {
        const $concessionNumber = $("#concession_number");

        if ($concessionNumber.length > 0) {
            validator.addCustomValidator($concessionNumber, () => {
                const membershipOption = getSelectedMembershipOption();
                const isConcession = membershipOption && membershipOption.concession === 'yes';

                if (isConcession && $concessionNumber.val() === '') {
                    return 'Concession number is required as the selected membership option is Concession';
                }

                return true;
            });
        }

        // Phone numbers
        const requirePhone = $phoneElements.data('require-phone');
        if (requirePhone) {
            for (let phoneEle of $phoneElements) {
                validator.addCustomValidator($(phoneEle), phoneValidator);
            }
        }
    }

    function phoneValidator() {
        clearPhoneValidationErrors();

        for (let phone of $phoneElements) {
            if (phone.value !== '') {
                return true;
            }
        }

        return 'At least one phone is required';
    }

    function clearPhoneValidationErrors() {
        for (let phone of $phoneElements) {
            removeFormErrors($(phone));
        }
    }

    function initHiddenElements() {
        $ylnpCheckboxDiv.hide();
        $lnpwCheckboxDiv.hide();
        $lnpwbranchlabel.hide();
        $lnpwBranchElem.hide();

        $suburbEle.hide();
        $streetAddress1Ele.hide();

        const automaticRenewal = $recurringPayment.prop('checked');
        if (!automaticRenewal) {
            $directDebitNote.hide();
        }
    }

    function isMobileNumber(number) {
        return number.slice(0, 3) === "+61" || number.slice(0, 2) === "04";
    }

    function formatMobileNumber(mobileNum) {
        // if our string does not start with a +, match the first 4 digits, then
        // append a space. If it does start with a +, match the first 2 digits,
        // then append a space. After the initial match, match every 3 digits, then
        // append a space.
        return mobileNum.replace(/((?:^\d{4}|^\+\d{2})(?!\D)|\d{3}(?!\b))/g, "$1 ").trim();
    }

    function formatLandlineNumber(phoneNum, prependAreaCode) {
        /* @todo Test this regex some more. */
        phoneNum = phoneNum.replace(/((?=^\d{9,}$)\d{2}|\d{4})/g, "$1 ").trim();

        if (phoneNum.length === 12) {
            // We'll assume this number contains an area code.
            phoneNum = "(" + phoneNum.slice(0, 2) + ")" + phoneNum.slice(2);
        }

        if (prependAreaCode) {
            if (phoneNum.length === 9 && phoneAreaCode) {
                // This is missing an area code.
                phoneNum = "(" + phoneAreaCode + ") " + phoneNum.slice(0, 10);
            }
        }

        return phoneNum;
    }

    function clearOrganisationDropdowns() {
        // First one for all branches is the "Select a branch" option
        $branchEle.find("option").not(":first").remove();
        $ylnpBranchEle.find("option").remove();
        $lnpwBranchEle.find("option").remove();

        for (let $ele of [$branchEle, $ylnpBranchEle, $lnpwBranchEle]) {
            $ele.find('optgroup').remove();
        }
    }

    /** =======================================================
     *                 Page callback functions
     * ======================================================== */
    // Note: return false from these functions to stop going to the next page.
    callbackFunctions.second_screen_callbacks = {
        before: function () {
            // Things to do before the second screen is submitted.
            // Clear our allocated organisations div.
            $("#allocated_organisations").empty();

            // Clear our available branches.
            clearOrganisationDropdowns();

            var $jointMember = $("#joint_member");
            var isJoint = $jointMember.prop('checked');

            // We might need to add the members to YLNP and LNPW branches.
            // Check the member's age and gender.
            var birthdate = getMemberBirthdateFromFormValues();
            var jointBirthdate = getMemberBirthdateFromFormValues(true);

            if (getAge(birthdate) <= 31 || (isJoint && getAge(jointBirthdate) <= 31)) {
                // Eligible for YLNP.
                $ylnpCheckboxDiv.show();
            }else {
                $ylnpCheckboxDiv.hide();
            }

            if ($("#gender").val() === "female" || (isJoint && $("#gender_joint").val() === "female")) {
                // Eligible for LNPW.
                $lnpwCheckboxDiv.show();
                //#18023 - show lnpw branches dropdown if genter is selected as female
                $lnpwbranchlabel.show();
                $lnpwBranchElem.show();
            }else {
                $lnpwCheckboxDiv.hide();
                $lnpwbranchlabel.hide();
                $lnpwBranchElem.hide();
            }
        },
        after: function (jsonResult) {
            hideAjaxSpinner();

            if (jsonResult.error) {
                showModalMessage(jsonResult.error, true);

                // Go back a page.
                goToPage(false);
            }

            // Things to do after the second screen AJAX submit has completed.
            if (jsonResult.added_organisations) {
                var prefix = "Automatically allocated to ";
                // We'll create an element to hold the name so that we can style it.
                var organisationNameEle = "<span class='organisation_name'></span>";

                var containerEle = "<div class='allocated_org'></div>";

                for (var organisationType in jsonResult.added_organisations) {
                    if (jsonResult.added_organisations.hasOwnProperty(organisationType)) {
                        var $organisationNameEle = $(organisationNameEle);
                        var $containerEle = $(containerEle);

                        $containerEle.text(prefix);

                        $organisationNameEle.text(jsonResult.added_organisations[ organisationType ]).appendTo($containerEle);

                        $("#allocated_organisations").append($containerEle);
                    }
                }
            }
        }
    };

    callbackFunctions.third_screen_callbacks = {
        before: () => showAjaxSpinner(),
        after: jsonResult => {
            // Fill our branch dropdowns with the results.
            // (We're returned an array if empty, an object otherwise)
            if (jsonResult.available_branches instanceof Array) {
                return;
            }

            clearOrganisationDropdowns();

            /**
             * Maps the returned array keys to the dropdown elements
             * we should be appending our results to.
             */
            const branchTypeMap = {
                branch: $branchEle,
                ylnp_branch: $ylnpBranchEle,
                lnpw_branch: $lnpwBranchEle
            };

            const optGroups = {};

            for (let branchType in jsonResult.available_branches) {
                const $branchEle = branchTypeMap[ branchType ];

                for (let branch of jsonResult.available_branches[branchType]) {
                    // Add an option group
                    let $appendTo = $branchEle;
                    if (branch.hasOwnProperty('optgroup')) {
                        // Append to the option group instead
                        $appendTo = optGroups[branch.optgroup] || (optGroups[branch.optgroup] = $(document.createElement('optgroup')));
                        $appendTo.attr('label', branch.optgroup);
                    }

                    $appendTo.append(`<option value="${branch.id}">${branch.name}</option>`);
                }

                for (const $optGroup of Object.values(optGroups)) {
                    // Add any optgroups we may have
                    $branchEle.append($optGroup);
                }
            }

            hideAjaxSpinner();
        }
    }

    callbackFunctions.fifth_screen_callbacks = {
        before: function () {
            var skipPaymentPage = false;

            $skipPaymentCheckboxes.each(function (this: any) {
                if ($(this).prop('checked') === true) {
                    // Skip the payment page.
                    skipPaymentPage = true;
                    return false;
                }
            });

            if (skipPaymentPage) {
                // Show a message, submit the page and redirect to the questionnaire.
                showModalMessage("Thank you for submitting your application.<br/><br/>One of our membership team will be in contact with you.<br/><br/>You will be redirected shortly...", true);

                var $curPage = $(".signup-form-section:visible");

                var then = Date.now();

                ajaxSubmitSignupForm($curPage, function () {
                    // Redirect 5 seconds after we submitted, or when the AJAX submit
                    // completes, whichever comes last. (This means it'll redirect after
                    // at LEAST a 5 second wait).
                    var elapsedTime = Date.now() - then;
                    var timeout = 0;

                    if (elapsedTime < 5000) {
                        timeout = 5000 - elapsedTime;
                    }

                    setTimeout(function () {
                        window.location.href = "/public/questionnaire";
                    }, timeout);
                });

                return false;
            }
        }
    };

    callbackFunctions.sixth_screen_callbacks = {
        after: function (jsonResult) {
            if (jsonResult.error) {
                if (jsonResult.error === "Invalid credit card number.") {
                    // Focus the CC number field. It gets unfocused
                    // when we dismiss the modal alert, but it should still
                    // bring some attention to it as it gets highlighted when
                    // focused.
                    $ccNumber.focus();

                    showModalMessage("Invalid credit card number, please try again or contact an administrator if problem persists.", true);
                } else if (jsonResult.error.includes("PAYMENT DECLINED")) {
                    //#18611 - If there is payment error then we show this message with error message
                    showModalMessage(jsonResult.error + "<br/><br/> Please try again with correct card details or if problem persists please contact an administrator.", true);
                } else {
                    showModalMessage(jsonResult.error + "<br/><br/>Please contact an administrator if problem persists.", true);
                }
            }else {
                if (jsonResult.contact_id) {
                    // Success! Redirect to the questionnaire page. (should be populated
                    // by SESSION).
                    window.location.href = "/public/questionnaire";
                }else {
                    showModalMessage("An unknown error has occurred. Please contact an administrator.", true);
                }
            }
        }
    };

    /** =======================================================
     *                End Page callback functions
     *  ======================================================= */

    /* ========================================================
    /*                    EVENT HANDLERS
    /* ======================================================== */

    // Change page/asynchronously submit out form when we click the "next" button.
    $(".but_next").on('click', function (this: any) {
        try {
            // Remove any previous input error divs.
            removeFormErrors();

            // Validate the current page.
            var validationResult = validateCurrentSection();

            if (validationResult !== true) {
                // Display the errors and return false.
                displayValidationErrors(validationResult);
                return false;
            }

            var $curPage = $(".signup-form-section:visible");
            var $currentForm = $curPage.find("form");

            // Move our token to our current form.
            $("#token").appendTo($currentForm);

            // Try to get a callback functions for the current page we're submitting.
            // The functions should be stored in an object with two optional keys:
            // "before" and "after". The object should be in the callbackFunctions
            // global object, with the name in the following example format:
            // callbackFunctions.second_screen_callbacks = { ... }
            var callbackFunctionName = $curPage.attr('id') + "_callbacks";
            var cbFunctions = typeof callbackFunctions[ callbackFunctionName ] === "object" ? callbackFunctions[ callbackFunctionName ] : {};

            // Call the BEFORE function now to handle any pre-submit processing.
            if (typeof cbFunctions.before === "function") {
                if (cbFunctions.before() === false) {
                    return false;
                }
            }

            if (goToPage(true)) {
                if ($curPage.is("#second_screen")) {
                    // Special case for the second screen:
                    // We need to ensure we have an electoral roll match
                    // before we continue to the next page, and that an
                    // existing contact is not already in the system.
                    showAjaxSpinner();
                }

                // Let's do an AJAX call to submit whatever we have so far.
                ajaxSubmitSignupForm($curPage, cbFunctions.after);

                // Don't submit the form.
                return false;
            }else {
                // No more pages.
                // Submit our form.

                // If this is the "make_payment" button,
                // let's add a hidden form element to let our server know
                // that we should make a payment.
                if ($(this).is(".but_make_payment")) {
                    $(this).parents("form").append("<input type='hidden' id='make_payment' name='make_payment' value='1' />");
                }else {
                    $("#make_payment").remove();
                }

                // Changing this to not submit the form. Instead, we'll do another AJAX
                // request. That way, we can process any payment errors and display
                // them to the user. return true;
                showAjaxSpinner();
                ajaxSubmitSignupForm($curPage, cbFunctions.after);
                return false;
            }
        }catch (e) {
            // If any of the above fails, we want to ensure that we do NOT submit the
            // form.
            alert("There was an error processing your application: " + e.message);
            return false;
        }
    });

    $(".but_prev").on('click', function () {
        goToPage(false);
        return false;
    });

    // Record the last clicked element on the page so that we can quickly return
    // to a previous page of the signup instead of showing validation messages.
    var lastClickedEle;
    $(document).on('mousedown', function (e) {
        // The latest clicked element
        lastClickedEle = $(e.target);
    });

    // Auto-format our phone fields.
    // Delegate the handler to the member details form so that these events
    // are also handled on our joint member phone inputs, too.
    $memberDetailsForm.on("change keypress input", phoneSelector, function (this: any, e) {
        // The "input" event will make this much nicer in some browsers, as it will
        // instantly format the phone number at the correct spot, whereas the
        // "keypress" event will only format it correctly at one character position
        // AFTER where a space would be added. This is because our $(this).val()
        // value will not include the newly inputted character.

        if (e.type === "keypress" && isEventSupported("input")) {
            // If the "input" event is supported, ignore the keypress event.
            return;
        }

        var $this = $(this),
            curVal = $this.val(),
            curLength = curVal.length,
            caretPos = $this.caret(),
            caretAtEnd = curLength === caretPos;

        var phoneNum = curVal;

        if (e.type === "keypress") {
            // This will only be used on browsers that don't support the "input"
            // event.

            // We'll be manually appending our character to the mobile number.
            // Prevent the default so that the user's input is not duplicated.
            e.preventDefault();

            var newChar: any = String.fromCharCode(e.which);

            if (e.which === 32 || e.which !== 8 && e.which !== 43 && isNaN(newChar)) {
                // We're only allowing +, backspace or a digit.
                // 32 is "space".
                // 8 is "backspace".
                // 43 is "+".
                return;
            }

            // Delete any selected text.
            // We need to do this manually as we are preventing the default behaviour.
            phoneNum = getTextAfterDeletingSelection($this[ 0 ]);

            // Append our new character to our mobile number.
            phoneNum += newChar;
        }

        // Strip any non-digit characters (except "+") to make it easier to format.
        phoneNum = phoneNum.replace(/(?![+])\D/g, '');

        // Check if this is a mobile number.
        if (isMobileNumber(phoneNum)) {
            $this.val(formatMobileNumber(phoneNum));
        }else {
            var prependAreaCode = e.type === "change";
            $this.val(formatLandlineNumber(phoneNum, prependAreaCode));
        }

        if (!caretAtEnd) {
            // If the caret wasn't at the end of our string, then let's attempt
            // reset it to the correct spot (after our last inserted character)
            /**
             * @fixme
             * does not currently position caret correctly when we add a digit
             * at a position where the previous character is a space.
             */
            var newPos = $this.val().length - curLength + caretPos;
            $this.caret(newPos);
        }
    });

    $allInputs.on('blur', function (this: any, e) {
        var $butPrev = $(".but_prev");

        // Commented out first if statement as it is unnecessary. This would be
        // useful if the thing we were checking was an input, in case it was not
        // clicked, but rather "tabbed" into or something like that. Since it is
        // just a button, we can check the lastClickedEle to see if it is our
        // button. if( $butPrev.is(e.relatedTarget) ||
        // $butPrev.is(e.originalEvent.explicitOriginalTarget) ) {
        if ($butPrev.is(lastClickedEle)) {
            // We've clicked on the "previous" button.
            // Let's avoid showing errors etc and just let the previous
            // button do its thing.
            // If you want to see why this is here, comment out the "return", go to
            // the "organisation details" page, click the dropdown, then click out of
            // it without making a selection, then click the previous button. It'll
            // bring up the "This field is required." validation error and won't move
            // to the previous screen as our button element will have moved a tiny
            // bit.
            return;
        }

        var $this = $(this);

        // Remove any errors for this element.
        //removeFormErrors($this);

        validateSingleElement($this);
    });

    $allInputs.on('focus', function (this: any) {
        // Let's remove errors on the inputs whenever we focus them to make our
        // form a bit more user-friendly!
        removeFormErrors($(this));
    });

    // Calculate our total amount whenever the donation amount or fee are changed.
    $("#fee, #donation_amount").on('change focus blur', function () {
        calculateTotalAmount();
    });

    // Show different divs whenever the payment method is changed.
    $paymentMethod.on('change', function () {
        var $bankDetails = $("#bank_details");
        var $ccDetails = $("#cc_details");
        var $transactionId = $("#transaction_id");

        switch ($paymentMethod.find("option:selected").text().trim().toLowerCase()) {
            case "cheque":
                $ccDetails.hide();
                $transactionId.show();
                $bankDetails.slideDown("medium");
                break;
            case "direct debit":
                $ccDetails.hide();
                $transactionId.hide();
                $bankDetails.slideDown("medium");
                break;
            case "credit card":
                $bankDetails.hide();
                $transactionId.hide();
                $ccDetails.slideDown("medium");
                break;
        }
    });

    $recurringPayment.on('change', e => {
        // #15235 - Only show direct debit note when "Automatically renew" is checked
        const method = $recurringPayment.prop('checked') ? 'slideDown' : 'slideUp';
        $directDebitNote[method]();
    });

    $("#joint_member").on('change', function (this: any) {
        var $this = $(this);
        if ($this.prop('checked')) {
            $this.val(1);
            showJointMemberDiv();

            ajaxGetFeeOptions("joint", processAjaxFeeOptionsResult);
            ajaxGetNameBadgeFee('joint', processAjaxNameBadgeFeeResult);
        }else {
            $this.val(0);
            hideJointMemberDiv();

            ajaxGetFeeOptions("individual", processAjaxFeeOptionsResult);
            ajaxGetNameBadgeFee('individual', processAjaxNameBadgeFeeResult);
        }
    });

    /**
     * A function used to remove the no-transition class for elements after we
     * have finished sliding them up or down.
     */
    var toggleCheckboxAnimationFinishedHandler = function (this: any) {
        $(this).removeClass("no-transition");
    };

    const showElement = ($element) => {
        // Disable transitions until we've finished animating our element.
        // We remove this class in toggleCheckboxAnimationFinishedHandler()
        $element.addClass("no-transition");
        $element.stop(true, true).slideDown('medium', toggleCheckboxAnimationFinishedHandler);
    };

    const hideElement = $element => {
        $element.addClass("no-transition");
        $element.stop(true, true).slideUp('medium', toggleCheckboxAnimationFinishedHandler);
    };

    var togglingCheckboxChangeHandler = function (this: any) {
        var $this = $(this);
        var $textbox = $($this.data('element-to-toggle'));

        if ($this.prop('checked')) {
            showElement($textbox);
        }else {
            hideElement($textbox);
        }
    };

    var $togglingCheckboxes = $('.toggling_checkbox');

    $togglingCheckboxes.each(function (i, checkbox) {
        // Make all toggle-able text fields initially hidden
        var $checkbox = $(checkbox);
        var $textbox = $($checkbox.data('element-to-toggle'));

        $textbox.hide();
    });

    $togglingCheckboxes.on('change', togglingCheckboxChangeHandler);

    // Setup autocomplete.
    var searchingForSuburbs = false;
    $postcodeEle.on('keyup change', function (e) {

        if (e.which !== 8 && (e.which < 48 || e.which > 57)) {
            // 8 = backspace key, 48-57 = 0-9

            // Ignore any keys except the backspace key & 0-9 so as to not
            // instantly focus the suburb field when a random
            // character is pressed in the postcode field.
            // For example, if a user shift-tabs (moves back a field)
            // into the postcode field, without this return statement, they
            // would instantly get focused back to the suburb field as the
            // ajaxGetSuburbs callback focuses the suburb field.
            return;
        }

        var postcodeVal = $postcodeEle.val();

        // Hide suburb and street address fields until we've
        // entered a valid postcode with suburb results.
        $suburbEle.empty();
        $suburbEle.hide();
        $streetAddress1Ele.hide();

        if (postcodeVal.length !== 4) {
            // Only do the lookup if our postcode is 4 digits long.
            return;
        }

        var data = {
            postcode: postcodeVal
        };

        searchingForSuburbs = true;

        // Add an AJAX spinner to our postcode field.
        $postcodeEle.addClass("ui-autocomplete-loading");

        // Don't show postcode error while we're searching
        validator.removeCustomError($postcodeEle);

        ajaxGetSuburbs(data, function (jsonResult) {
            searchingForSuburbs = false;
            $postcodeEle.removeClass("ui-autocomplete-loading");

            // Fill it with our retrieved values.
            // First, clear out any existing values.
            $suburbEle.empty();

            var response = jsonResult.response;
            var resultUndefined = response === undefined || typeof response.result === "undefined";

            if (resultUndefined || response.result.length === 0) {
                validator.setCustomError($postcodeEle, postcodeCustomErrMsg);
                $suburbEle.append("<option disabled='disabled'>No suburbs found for given postcode</option>");

                validateSingleElement($postcodeEle);

                return;
            }

            validator.removeCustomError($postcodeEle);

            // Add a "Select suburb" option.
            $suburbEle.append("<option disabled='disabled'>Select suburb</option>");
            for (var x in response.result) {
                var suburbStr = response.result[ x ];
                $suburbEle.append("<option value=\"" + suburbStr + "\">" + suburbStr + "</option>");
            }

            $suburbEle.show();
            $streetAddress1Ele.show();

            // First, focus the postcode field in case our custom error is showing.
            // This will ensure that it is removed before we focus our suburb element.
            $postcodeEle.focus();
            $suburbEle.focus();
        });
    });

    /**
     * Stores our autocomplete results based on different search criteria.
     * @type {Object}
     * @todo move the cache into the ajaxGetStreetAddresses function so that it
     *   is more centralised.
     */
    var streetAddressCache = {};
    $streetAddress1Ele.autocomplete({
        source: function (request, response) {
            // #14146 - Remove loading spinner in text field
            this.element.removeClass('ui-autocomplete-loading');

            // Add our suburb and postcode into our request.
            request.suburb = $suburbEle.val();
            request.postcode = $postcodeEle.val();
            request.query = request.term;

            // Try to get results from cache.
            // This cache would only ever really be hit if the user deletes their
            // input, but it doesn't hurt to have it here :).
            var cacheKey = request.suburb + request.postcode + request.query;

            if (streetAddressCache.hasOwnProperty(cacheKey)) {
                response(streetAddressCache[ cacheKey ]);
                return;
            }

            ajaxGetStreetAddresses(request, function (data) {
                if (data.response !== undefined) {
                    streetAddressCache[ cacheKey ] = data.response.result;
                    response(data.response.result);
                }else {
                    response([]);
                }
            });
        },
        delay: 300,
        autoFocus: true,
        minLength: 2,
        html: true, // optional (jquery.ui.autocomplete.html.js required)

        // optional (if other layers overlap autocomplete list)
        open: function (event, ui) {
            $(".ui-autocomplete").css("z-index", 1000);
        }
    });

    /**
     * These checkboxes trigger other checkboxes to be checked
     * or unchecked, when checked!
     * @type {jQuery|HTMLElement}
     */
    var $toggleCheckboxes = $(".checkbox_toggle");

    $toggleCheckboxes.each(function (this: any) {
        var $toggleCheckbox = $(this);

        // Get the checkboxes we should toggle when this checkbox
        // is checked or unchecked.
        var checkboxesToToggleSelector = $toggleCheckbox.attr('data-selector');
        var $checkboxesToToggle = $(checkboxesToToggleSelector);

        // Set up click events for these checkboxes to uncheck or check
        // our initial toggling checkbox when they are ALL checked or ALL
        // unchecked.
        $checkboxesToToggle.on('click', function () {
            var doCheck = false;
            $checkboxesToToggle.each(function (this: any) {
                if ($(this).prop('checked')) {
                    // One of the checkboxes is checked.
                    // Let's also check the toggle, then.
                    doCheck = true;
                    return false;
                }
            });

            $toggleCheckbox.prop('checked', doCheck);
        });
    });

    /**
     * When a toggle is clicked, the checkboxes it toggles will
     * ALL be unchecked if the toggle is unchecked.
     */
    $toggleCheckboxes.on('click', function (this: any) {
        var $this = $(this);

        if (!$this.prop('checked')) {
            var checkboxesToToggleSelector = $this.attr('data-selector');
            var $checkboxesToToggle = $(checkboxesToToggleSelector);

            $checkboxesToToggle.prop('checked', false);
        }
    });

    $skipPaymentCheckboxes.on('change', function () {
        var isChecked = false;

        $skipPaymentCheckboxes.each(function (this: any) {
            if ($(this).prop('checked')) {
                isChecked = true;
                return false;
            }
        });

        if (isChecked) {
            $(".but_make_payment").text("Submit");
        }else {
            $(".but_make_payment").text("Next");
        }
    });

    // Init initial values after event listeners have been established
    initElementInitialValues();

    var currentCCClass;
    $ccNumber.validateCreditCard(function (result) {
        removeCustomError($ccNumber);

        if ($paymentMethod.find("option:selected").text().trim().toLowerCase() !== "credit card") {
            // Only validate if credit card is the payment method.
            return;
        }

        if (typeof currentCCClass !== "undefined") {
            $ccNumber.removeClass(currentCCClass);
        }

        if (result.valid !== true) {
            markCardFieldInvalid(invalidCCNumberMessage);
        }else {
            $ccNumber.addClass("cc-type-" + result.card_type.name);
            currentCCClass = "cc-type-" + result.card_type.name;

            if (result.card_type.name === "amex") {
                // Disallow AMEX payments.
                markCardFieldInvalid(amexCCNotAllowedMessage);

                // Instantly display our custom error.
                displayValidationErrorsForSingleElement($ccNumber, validator.getCustomError($ccNumber));
                return false;
            }

            // Mark our textbox as valid.
            $ccNumber.addClass("cc-valid");
        }
    });

    function markCardFieldInvalid(msg) {
        $ccNumber.addClass("cc-type-none");

        // Remove the cc-valid class.
        $ccNumber.removeClass("cc-valid");

        validator.setCustomError($ccNumber, msg);
    }

    function modalBlockClickHandler(e) {
        // Only hide the modal block if we've clicked the actual block or the
        // x button, not any of the children elements, such as the message
        // box.
        var validClosingElements = [
            $modalBlock[ 0 ],
            $(".modal-x")[ 0 ]
        ];

        for (var i = 0; i < validClosingElements.length; i++) {
            if (e.target == validClosingElements[ i ]) {
                $modalAlert.slideUp(undefined, function () {
                    $modalBlock.hide();
                });
                return;
            }
        }

        $modalBlock.one('click', modalBlockClickHandler);
    }

    // Polyfill Date.now if it does not exist.
    if (!Date.now) {
        Date.now = function now() {
            return new Date().getTime();
        };
    }
});

import {isEventSupported} from "../events";

declare const $;
declare const baseUrl;

$(document).ready(function () {
    const btnPrev = $(".buttonPrevious");

    const $wizard = $('#wizard');
    if ($wizard.length === 0) {
        // Only run on pages with the wizard
        return;
    }

    // Initialize Smart Wizard
    $wizard.smartWizard({
        selected: 0,  // Selected Step, 0 = first step
        keyNavigation: false, // Enable/Disable key navigation(left and right keys are used if enabled)
        enableAllSteps: false,  // Enable/Disable all steps on first load
        transitionEffect: 'fade', // Effect on navigation, none/fade/slide/slideleft
        cycleSteps: false, // cycle step navigation
        enableFinishButton: false, // makes finish button enabled always
        errorSteps: [],    // array of step numbers to highlighting as error steps
        labelNext: 'Next', // label for Next button
        labelPrevious: 'Previous', // label for Previous button
        labelFinish: 'Run Report',  // label for Finish button

        // Events
        onLeaveStep: null, // triggers when leaving a step
        onShowStep: onShowStepCallback,  // triggers when showing a step
        onFinish: onFinishCallback  // triggers when Finish button is clicked
    });

    //Show any errors to the user
    if ($("#msg").length != 0) {
        alert($("#msg").html());
    }

    //Initialise all date pickers
    var datepickerOptions: DatePickerOptions = {
        dateFormat: 'dd-mm-yy',
        changeYear: true,
        changeMonth: true,
    };

    $(".date-pick").each(function (this: HTMLElement) {
        const $this = $(this);
        let minDate, maxDate;

        delete datepickerOptions.minDate;
        delete datepickerOptions.maxDate;

        // Check if we've defined a min/max date for the
        // datepicker.
        if (minDate = $this.attr('data-min-date')) {
            datepickerOptions.minDate = minDate;
        }

        if (maxDate = $this.attr('data-max-date')) {
            datepickerOptions.maxDate = maxDate;
        }

        $this.datepicker(datepickerOptions);
    });

    // If we're going to the first step, we want the "Previous" button to be enabled.
    function onShowStepCallback(obj, context) {
        if (context.toStep === 1) {
            btnPrev.removeClass("buttonDisabled");
        }
        return true;
    }

    function onFinishCallback() {
        $("#report-gen-text").fadeOut(300, function () {
            $("#report-gen-loader").fadeIn("slow", function () {
                $('#wizard-form').submit();
            });
        });
    }

    // Go to selector if we're on the first step and we've clicked back.
    // prevClickedOnStep1 is custom defined by us in the wizard.js library
    btnPrev.on('click', function () {
        var wiz = $("#wizard").data('smartWizard');
        if (wiz.options.prevClickedOnStep1 === 1) {
            window.location.href = baseUrl() + "/reports/selector";
        }
    });

    var $reportName = $("#report_name");

    if ($reportName.attr("contenteditable")) {
        var $reportForm = $reportName.closest("form");

        var titleTokens = {};

        $.ajax({
            url: "reports/ajaxgetvalidreporttitletokens",
            method: "GET",
            dataType: "json",
            success: function (data) {
                titleTokens = data;
                formatReportTitleTokens(titleTokens);
            }
        });

        $reportName.on("input keypress keydown", function (this: HTMLElement, e) {
            if (e.type === "keydown") {
                if (e.which === 8) {
                    // If the backspace is pressed, delete our zero width non-joiner element.
                    // (This element is used to ensure our content-editable field can be appended to. Chrome works without it, FF does not.)
                    $(this).find(".contenteditable-zero-width-non-joiner").remove();
                }

                return;
            }

            if (isEventSupported("input") && e.type === "keypress") {
                // If the input event is supported, we can safely ignore the
                // keypress event.
                return;
            }

            formatReportTitleTokens(titleTokens);
        });

        $reportName.on('blur', function (this: HTMLElement) {
            $(this).scrollLeft(0);
        });

        $reportForm.on('submit', function () {
            // Set our hidden report name field's value when we submit the form.
            // First, remove the zero-width non-joiner.
            var $reportNameInput = $("input[name=name]");
            $reportName.find(".contenteditable-zero-width-non-joiner").remove();
            $reportNameInput.val($reportName.text());
        });
    }

    $(".report-content").on('mouseenter click', ".report-name-token", function (this: HTMLElement, e) {
        // Delegate mouseenter event to the report name token spans.
        var $this = $(this);

        if (e.type === "click") {
            // Uncomment if you want the tokens to be selected
            // when clicked, by transforming the span into an <input>
            // element until it is blurred.
//             var text = $this.text();
//             var style = $this.getStyleObject();
//
//             var $input = $("<input type=text />");
//             $input.css(style)
//                 .val(text);
//
//             $input.width($this.width());
//             $this.after($input);
//
//             $input.focus();
//             $input.select();
//
//             $input.on('blur', function() {
//                 $this.text($input.val());
//                 $input.remove();
//                 $this.show();
//             });
//
//             $this.hide();
//             return;
        }

        var content = $($this.attr("data-tooltip-ele")).val();
        $this.attr('title', content);

        if (!$this.data('ui-tooltip')) {
            $this.tooltip();
            $this.tooltip("open");
        }

        $this.tooltip("option", "content", content);
    });

    // if 'show electoral mismatches' checkbox is checked and if there is 'show feedback id' checkbox
    // this will check the 'show feedback id' checkbox and disable it as checking 'show electoral mismatches' already shows feedback id.
    $("#show_electoral_mismatches").on("click", function () {
        if ($("#show_electoral_mismatches").is(":checked")) {
            if ($("#show_feedback_id").length != 0) {
                $("#show_feedback_id").prop('checked', true);
                $("#show_feedback_id").prop("disabled", true);
            }
        } else {
            if ($("#show_feedback_id").length != 0) {
                $("#show_feedback_id").prop("disabled", false);
            }
        }
    });

    //Initialise all date pickers
    let monthdaypickerOptions: DatePickerOptions = {
        dateFormat: 'dd-mm',
        changeYear: true,
        changeMonth: true
    };

    $(".month-day-pick").each(function (this: any) {
        var $this = $(this);
        var minDate, maxDate;

        delete monthdaypickerOptions.minDate;
        delete monthdaypickerOptions.maxDate;

        // Check if we've defined a min/max date for the
        // datepicker.
        if (minDate = $this.attr('data-min-date')) {
            monthdaypickerOptions.minDate = minDate;
        }

        if (maxDate = $this.attr('data-max-date')) {
            monthdaypickerOptions.maxDate = maxDate;
        }

        $this.datepicker(monthdaypickerOptions).focus(function () {
            $(".ui-datepicker-year").hide();
        });
    });
});

function formatReportTitleTokens(titleTokens) {
    var $reportName = $("#report_name");
    var reportName = $reportName.text();

    var caretPos = $reportName.caret();

    // Match anything between "{{" AND "}}".
    var pattern = /{{[\w\d]+}}/g;

    // Remove our zero-width non-joiner.
    reportName = reportName.replace(/\u200C/g, "");

    reportName = reportName.replace(pattern, function (match) {
        if (titleTokens[match] !== undefined) {
            return "<span class='report-name-token' data-tooltip-ele='" + titleTokens[match] + "' style='display: inline; color: green; font-size: inherit; '>" + match + "</span>";
        }

        // Invalid token.
        return "<span class='report-name-token' title='Invalid token - will be treated as a literal.' style='display: inline; color: red; font-size: inherit; '>" + match + "</span>";
    });

    // Add an empty span to allow us to continue editing the report name
    // without the formatting of the last token applied.
    // (Comment this line and try editing the report name in FireFox to see reason for it.)
    reportName += "<span class='contenteditable-zero-width-non-joiner' style='display: inline;'>&zwnj;</span>";

    $reportName.html(reportName);

    $reportName.caret(caretPos);
}

// @ts-ignore
window.chooseOrganisationType = function () {
    var currently_selected = $("#organisation-chooser").val();

    $('.organisation-choice').each(function (this: HTMLElement) {
        const $this = $(this);

        if ($this.attr("id") == "report-" + currently_selected) {
            $this.show();
        } else {
            $this.hide();
        }
    });
}

function choosePositions() {
    const currently_selected = $("#position-chooser").val();
    if (currently_selected == "1") {
        $("#position_types").show("fast");
    } else {
        $("#position_types").hide("fast");
        selectElements("positions", 1);
    }
}

// @ts-ignore
window.choosePositions = choosePositions;

//Allows you to select or deselect all checkboxes that have a certain name
function selectElements(name, status) {
    $('input[type="checkbox"][name="' + name + '[]"]:visible').each(function (this: any) {
        if (status == 0) {
            $(this).prop("checked", false);
        } else {
            $(this).prop("checked", true);
        }
    });
}

// @ts-ignore
window.selectElements = selectElements;

/*
 * Pum's edit
 * This is called from Select All Checkbox
 * The reason to do this is to sent only select all checkboxes.
 * This because of there are too many organisation checkboxes and it causes error when we pass them the post method.
 */
function selectAllCheckbox(element_name, checkbox_id, select_all_class) {
    const checkbox = document.getElementById(checkbox_id) as HTMLInputElement;

    if (checkbox.checked) {
        //select all elements and then disable them.
        selectElements(element_name, 1);
        $("input." + select_all_class).attr("disabled", true);
    } else {
        //un-select all elements and then enable them.
        selectElements(element_name, 0);
        $("input." + select_all_class).removeAttr("disabled");
    }
}

// @ts-ignore
window.selectAllCheckbox = selectAllCheckbox;

function chooseMemberStatus() {
    $("#memberstatus-chooser").on('change', function (this: any) {

        $("#membertypes").hide();
        $("#memberstatuses").hide();

        $("#" + $(this).val()).show();

        $(".membership_types").prop('checked', false);
        $(".membership_statuses").prop('checked', false);
    });
}

// @ts-ignore
window.chooseMemberStatus = chooseMemberStatus;

function chooseJoinDates() {
    var currently_selected = $("#joindate").val();
    if (currently_selected == "all") {
        $("#joindatesdiv").hide("fast");
    } else {
        $("#joindatesdiv").show("fast");
    }
}

// @ts-ignore
window.chooseJoinDates = chooseJoinDates;

function chooseMembershipOptions() {
    var currently_selected = $("#membership_options").val();
    if (currently_selected == 'all') {
        $("#membershipoptions").hide('fast');
    } else {
        $("#membershipoptions").show('fast');
    }
}

// @ts-ignore
window.chooseMembershipOptions = chooseMembershipOptions;

type DatePickerOptions = {
    dateFormat: string;
    changeYear: boolean;
    changeMonth: boolean;
    minDate?: string;
    maxDate?: string;
}

import {documentReady} from "./document-ready";
import {setupOAuthPopup} from "./oauth-popup";
import cssVars from 'css-vars-ponyfill';
import {importRouteJs, controller, action} from "./routes";

// CSS Variables Polyfill for legacy browsers
cssVars();

// Import the js for this controller/action, if it exists.
importRouteJs(controller, action)
    .catch(() => 'No file found, that\'s ok.');

documentReady().then(() => {
    setupOAuthPopup();
});

declare const $;

/**
 * A class used for validating fields via an object, with keys as selectors and rules as values.
 * @author Dejan
 */
export class DValidator {
    private customErrors = {};
    private  customValidators = {};

    private _validatorFunctions = {
        required: function(elementVal, isRequired) {
            if( isRequired ) {
                // If it's a string, check if the length is > 0.
                // If it's any other value, cast to boolean and return. (This should be the "checked" prop of a radio/checkbox or NULL values, as a dropdown could return).
                return typeof elementVal === "string" ? elementVal.trim().length > 0 : !!elementVal;
            }

            // If it's not required, just return true no matter the value.
            return true;
        },
        exactLength: function(elementVal, len) {
            return elementVal.trim().length === 0 || elementVal.trim().length === len;
        },
        minLength: function(elementVal, min) {
            return elementVal.trim().length === 0 || elementVal.trim().length >= min;
        },
        maxLength: function(elementVal, max) {
            return elementVal.trim().length === 0 || elementVal.trim().length <= max;
        },
        numeric: function(elementVal, isNumeric) {
            if( isNumeric ) {
                return elementVal.trim().length === 0 || (elementVal.trim().length > 0 && !isNaN(elementVal.trim()));
            }

            return true;
        }
    };

    /**
     * Validates elements based on some given rules.
     * @param {object} rules An object with element ID's as properties, each containing objects of rules to validate. (See example in function body comment)
     * @returns {object|boolean} True if the validation passed, an object of errors otherwise.
     */
    validate(rules) {
        /*
         *      {
         *          myForm: {
         *              required: true,
         *              minLength: 5,
         *              numeric: true
         *          }
         *      }
         */
        var errors = {};
        var hasErrors = false;

        for( var selector in rules ) {
            if( rules.hasOwnProperty(selector) ) {
                // Get our element value from the DOM.
                var $ele = $(selector);

                var valResult = this.validateSingle($ele, rules);

                if( typeof valResult === "object" ) {
                    // We received an object of errors back.
                    hasErrors = true;
                    errors[ selector ] = valResult;
                }
            }
        }

        if( hasErrors ) {
            return errors;
        }

        return true;
    }

    /**
     * Validates a single element against an object of rules.
     *
     * @param {jQuery} $ele The element to validate.
     * @param {object} rules The rules to validate the element against.
     * @returns {object|boolean} True if the validation passed, an object of errors otherwise.
     */
    validateSingle($ele, rules) {
        const id = $ele.attr('id');

        // First, check if there is a custom error for this element.
        if (this.customErrors.hasOwnProperty(id)) {
            return {
                customError: this.customErrors[id]
            };
        }

        if (this.customValidators.hasOwnProperty(id)) {
            // Call the custom validator
            var result = this.customValidators[ id ]();
            if (result === true) {
                return true;
            }

            return {
                customError: result
            }
        }

        for( var selector in rules ) {
            if( rules.hasOwnProperty(selector) ) {
                // Check to see if our element matches the selector.
                if( $ele.is(selector) ) {
                    // We've matched!
                    // Dig into the object to get the rules for this element.
                    var elementRules = rules[ selector ];

                    // We're going to re-create our element so we get all the elements
                    // that match this selector.
                    // This ensures that when we're validating single elements, we don't falsely
                    // mark it as failing validation.
                    // For example, if the element we're validating is a checkbox and the selector
                    // we've matched is supposed to match all the checkboxes, without re-creating,
                    // we'd only be checking if the passed checkbox is checked, not all of the checkboxes.
                    $ele = $(selector);

                    var valResult = this._validateSingle($ele, elementRules);

                    if( typeof valResult === "object" ) {
                        // We received an object of errors back.
                        return valResult;
                    }
                }
            }
        }

        return true;
    };

    /**
     * Allows adding a custom error to an element.
     *
     * This will force an element to fail validation.
     * The returned validation error object will have a <i>customError</i> key.
     *
     * @param {jQuery} $ele The JQuery element to add a custom error for.
     * @param {String} errMsg The error message for this custom error.
     * @see DValidator.removeCustomError
     */
    setCustomError($ele, errMsg) {
        var eleId = $ele.attr('id');

        if( typeof eleId === "undefined" || eleId === false ) {
            throw new Error("[DValidator] Cannot add custom error for element with no Id attribute.");
        }

        this.customErrors[ $ele.attr('id') ] = errMsg;
    };

    /**
     * Removes a custom error for an element.
     * @param {jQuery} $ele The element to remove the custom error for.
     */
    removeCustomError($ele) {
        delete this.customErrors[ $ele.attr('id') ];
    };

    getCustomError($ele) {
        return {customError: this.customErrors[ $ele.attr('id') ]};
    };

    addCustomValidator ($ele, fn) {
        var id = $ele.attr('id');
        if (!id) {
            throw new Error("[DValidator] Cannot add custom validator for element with no Id.");
        }

        this.customValidators[ id ] = fn;
    };

    /**
     * Checks a condition object.
     * @param {Object} conditions
     * @returns {boolean} True if the condition was met, false otherwise.
     * @private
     */
    private _checkConditions(conditions) {
        var satisfied = false;

        for( var i = 0; i < conditions.length; i++ ) {
            // This outer loop represents the "AND" conditions.
            var condition = conditions[i];

            or_break:
            for( var conditionElementSelector in condition ) {
                var $conditionEle = $(conditionElementSelector);
                // This inner loop represents the OR conditions.
                if( condition.hasOwnProperty(conditionElementSelector) ) {
                    var conditionExpectedValues = condition[ conditionElementSelector ];

                    if( !$.isArray(conditionExpectedValues) ) {
                        // This means it is not an array of values.
                        // convert it to an array so that we can iterate over it.
                        conditionExpectedValues = [conditionExpectedValues];
                    }

                    // Now that it is definitely an array, we can iterate over all the condition values.
                    for( var x = 0; x < conditionExpectedValues.length; x++ ) {
                        var conditionExpectedValue = conditionExpectedValues[ x ];

                        if( typeof conditionExpectedValue === "object" ) {
                            /*if( !conditionExpectedValue.hasOwnProperty("is") ) {
                                throw new Error("DValidator: If condition is an object, but no \"is\" key was given.");
                            }*/

                            // If we have something like the following:
                            /**
                             "if": [
                                {
                                    "#previously_nominated_checkbox": {
                                        "is": ":checked"
                                    }
                                }
                             ]
                             */

                            // We'll do a jQuery "is" check.
                            for( var fnName in conditionExpectedValue ) {
                                if( $conditionEle[ fnName ](conditionExpectedValue[ fnName ]) ) {
                                    satisfied = true;
                                    break or_break;
                                }
                            }
                        }else {
                            // If we have something like the following:
                            /**
                             "if": [
                                 {
                                     "#previously_nominated_checkbox": "checkVal"
                                 }
                             ]
                             */

                            // We'll simply check that our element's value matches the "checkVal".
                            if( $conditionEle.val() == conditionExpectedValue ) {
                                satisfied = true;
                                // Since this is an "OR" condition, we can simply break out when one is satisfied.
                                break or_break;
                            }else {
                                satisfied = false;
                            }
                        }
                    }
                }
            }

            if( !satisfied ) {
                // Since this is an "AND", if we're not satisfied by now, we can simply return false as the condition hasn't been met.
                return false;
            }
        }

        return true;
    };

    private _validateSingle($ele, elementRules) {
        var errors: any = {};
        var hasErrors = false;

        var elementValue;
        if( $ele[0].type === "checkbox" || $ele[0].type === "radio" ) {
            elementValue = $ele.filter(":checked").length > 0;
        }else {
            elementValue = $ele.val();
        }

        // First, check if this element is actually required and if we have a value.
        if( typeof elementRules.required !== "object" && elementRules.required !== true && elementValue.trim().length === 0 ) {
            // Ignore validation as this element is not required.
            return true;
        }

        // If the rules for this element are an object, we can proceed.
        if( typeof elementRules === "object" ) {

            // Loop through each rule in our rules object.
            for( var ruleName in elementRules ) {
                if( elementRules.hasOwnProperty(ruleName) ) {
                    var ruleValue = elementRules[ ruleName ];

                    if( typeof ruleValue === "object" ) {
                        // We have a condition on this rule.
                        if( !this._checkConditions(ruleValue.if) ) {
                            // The condition was not met, which means we can ignore this rule.
                            return true;
                        }
                    }

                    // Check if a function exists for this ruleName in our validatorFunctions.
                    if( this._validatorFunctions.hasOwnProperty(ruleName) ) {
                        // Call the function from our validatorFunctions object.
                        // All the functions should take the elementValue as the first param
                        // and an optional ruleValue for the second param (e.g. minLength ruleValue would
                        // be the minimum length for the element).
                        if( !this._validatorFunctions[ ruleName ](elementValue, ruleValue) ) {
                            errors[ ruleName ] = ruleValue;
                            hasErrors = true;
                        }
                    }
                }
            }
        }

        if( hasErrors ) {
            return errors;
        }

        return true;
    };
}

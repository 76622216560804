import {DEFAULT_POPUP_FEATURES, OAuthPopup} from "@cbar/oauth-popup";

const getCenterOfElement = element => {
    const rect = element.getBoundingClientRect();

    return {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
    };
};

const loginUrl = '/auth/sociallogin?provider=authentickated';

export const setupOAuthPopup = () => {
    if (OAuthPopup.isPopupWindow()) {
        // We're inside the OAuth popup.
        return handlePopupCallback();
    }

    const popup = new OAuthPopup;
    const loginButtons = document.querySelectorAll('.authentickated-login');

    const handler = function (this: HTMLButtonElement, event) {
        const e = event as MouseEvent;
        e.preventDefault();

        const buttonCenter = getCenterOfElement(this);

        // Open the popup around the clicked button
        let features = {
            ...DEFAULT_POPUP_FEATURES,
            top: Math.max(buttonCenter.y - DEFAULT_POPUP_FEATURES.height / 2),
            left: Math.max(buttonCenter.x - DEFAULT_POPUP_FEATURES.width / 2, 0),
        };

        if (popup.isPopupOpen()) {
            // Popup is already open, calling open now will just
            // focus the existing popup.
            return popup.open(loginUrl, features);
        }

        // Update the button text
        const btnText = this.innerHTML;
        this.innerHTML = 'Enter your login details in the popup...';

        popup.open(loginUrl, features)
            .then(({redirectUrl}) => {
                this.innerHTML = 'Redirecting you now...';

                window.location.href = redirectUrl;
            }).catch(e => {
                console.error(e);
                this.innerHTML = btnText;
            });

        window.addEventListener('beforeunload', () => {
            // Close the popup if the window unloads.
            popup.close();
        });
    };

    loginButtons.forEach(button => {
        // Start a timer to see if the popup gets blocked
        button.addEventListener('click', handler.bind(button));
    });
};

export const handlePopupCallback = () => {
    const isLoginPage = window.location.pathname.indexOf('/auth/login') === 0;

    if (isLoginPage) {
        // We got redirected back to the login page in the popup,
        // let's re-start the login process.

        // Hide the page so that the login page does not flash in before we redirect.
        document.body.style.display = 'none';

        return window.location.href = loginUrl;
    }

    // Retrieve redirect url from the hidden input in the /auth/social view
    const input = document.querySelector('#redirect_url') as HTMLInputElement;
    const redirectUrl = input ? input.value : '/index/home';

    // Attempt to notify if we're already in a popup.
    OAuthPopup.notifyOpener({redirectUrl});
};

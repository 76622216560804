const supportedEvents = {};

export function isEventSupported(originalEventName) {
    if (supportedEvents[originalEventName] === undefined) {
        let el: any = document.createElement("div");
        const eventName = "on" + originalEventName;

        var isSupported = (eventName in el);
        if (!isSupported) {
            el.setAttribute(eventName, "return;");
            isSupported = typeof el[eventName] === "function";
        }
        el = null;

        supportedEvents[originalEventName] = isSupported;
    }

    return supportedEvents[originalEventName];
}
